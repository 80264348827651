import type { EntityType } from "$lib/server/entities/EntityType";
import type { CommentTarget } from "$lib/server/entities/comment/CommentTarget";

export type UTMTracking = {utm_campaign?: string, utm_source?: string, utm_medium?: string};

export function generate_view_entity_url(entity: {entity_type: EntityType, uuid: string}, 
                                         base_url: string, 
                                         group_uuid?: string,
                                         tracking?: UTMTracking)
    : {url: URL, full_path: string} {

    let url: URL;
    if (entity.entity_type === 'track') {

        url = new URL(`/tracks/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'pool') {

        url = new URL(`/pools/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'discussion') {

        url = new URL(`/discussions/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'comment') {

        let target_entity: CommentTarget;
        let base_comment_entity: any = entity; // TODO: Find a way of leveraging the type-system to eliminate type use of 'any'.
        if (base_comment_entity.pool) {
            target_entity = base_comment_entity.pool;
        } else if (base_comment_entity.workout) {
            target_entity = base_comment_entity.workout;
        } else if (base_comment_entity.track) {
            target_entity = base_comment_entity.track;
        } else if (base_comment_entity.discussion) {
            target_entity = base_comment_entity.discussion;
        } else if (base_comment_entity.track_group_share) {
            target_entity = base_comment_entity.track_group_share.track;
        } else if (base_comment_entity.pool_group_share) {
            target_entity = base_comment_entity.pool_group_share.pool;
        } else if (base_comment_entity.workout_group_share) {
            target_entity = base_comment_entity.workout_group_share.workout;
        } else {
            // console.error(entity);
            // console.error("-----------------");
            // console.error(base_comment_entity);
            throw new Error(`Unsupported comment subtype: ${base_comment_entity?.entity_type}`);
        }

        url = generate_view_entity_url(target_entity, base_url).url;
        url.searchParams.append('comment_uuid', entity.uuid);
    } else if (entity.entity_type === 'group') {

        url = new URL(`/groups/${entity.uuid}/view`, base_url);

    } else if (entity.entity_type === 'workout') {

        url = new URL(`/workouts/${entity.uuid}/view`, base_url);
    } else {
        throw new Error(`Unsupported entity type - ${entity?.entity_type}`);
    }

    if (tracking?.utm_campaign) {
        url.searchParams.append('utm_campaign', tracking.utm_campaign);
    }
    if (tracking?.utm_source) {
        url.searchParams.append('utm_source', tracking.utm_source);
    }
    if (tracking?.utm_medium) {
        url.searchParams.append('utm_medium', tracking.utm_medium);
    }

    if (group_uuid) {
        url.searchParams.append('group_uuid', group_uuid);
    }

    return {url, full_path: `${url.pathname}${url.search}`};
}